import '@fortawesome/fontawesome-free/js/all.min.js';


var darkSwitch = document.getElementById("darkSwitch");
window.addEventListener("load", function () {
    if (darkSwitch) {
        initTheme();
        darkSwitch.addEventListener("change", function () {
            resetTheme();
        });
    }
    //$('#js--preloader-bof').fadeOut('slow');
    //document.getElementById("js--preloader-bof").hidden = false;

});


function initTheme() {
    var darkThemeSelected =
        localStorage.getItem("darkSwitch") !== null &&
        localStorage.getItem("darkSwitch") === "dark";
    darkSwitch.checked = darkThemeSelected;
    darkThemeSelected
        ? document.body.setAttribute("data-theme", "dark")
        : document.body.removeAttribute("data-theme");
    if (darkThemeSelected == false) {
        document.getElementById("icon-swith").classList.add("fa-sun");
        document.getElementById("icon-swith").classList.remove("fa-cloud-moon");
        CloseDarkSwitch();
    } else {
        OpenDarkSwitch();
        document.getElementById("icon-swith").classList.remove("fa-sun");
        document.getElementById("icon-swith").classList.add("fa-cloud-moon");

    }

}
function resetTheme() {
    if (darkSwitch.checked) {
        document.body.setAttribute("data-theme", "dark");
        localStorage.setItem("darkSwitch", "dark");

        OpenDarkSwitch();
        document.getElementById("icon-swith").classList.remove("fa-sun");
        document.getElementById("icon-swith").classList.add("fa-cloud-moon");

    } else {
        document.body.removeAttribute("data-theme");
        localStorage.removeItem("darkSwitch");

        CloseDarkSwitch();
        document.getElementById("icon-swith").classList.add("fa-sun");
        document.getElementById("icon-swith").classList.remove("fa-cloud-moon");
    }
}

function OpenDarkSwitch() {
    const open = "open";
    const urlReq = `/home/SetNigthMode?data=${open}`
    const requestOptions = {
        method: 'POST'
    };
    fetch(urlReq, requestOptions)
        .then(function (response) {
            return response.json();
        })
        .then(function (result) {

            if (result) {

            }

        })
        .catch(error => console.log('error', error));
}

function CloseDarkSwitch() {
    const close = "close";
    const urlReq = `/home/SetNigthMode?data=${close}`
    const requestOptions = {
        method: 'POST'
    };
    fetch(urlReq, requestOptions)
        .then(function (response) {
            return response.json();
        })
        .then(function (result) {

            if (result) {

            }

        })
        .catch(error => console.log('error', error));
}

$(document).ready(function () {
    $("body")
        .off('click', '#js--logout')
        .on('click', '#js--logout', function () {
            Signout();
        })
        .off('click', '#js--logout-member')
        .on('click', '#js--logout-member', function () {
            SignoutMember();
        })

    
})
function Signout() {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            //window.location = "/Partner/SignIn";
            location.reload();
        }
    };
    xhttp.open("POST", "/partner/signout", true);
    xhttp.send();

}

function SignoutMember() {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            window.location = "/member/SignIn";
            //location.reload();
        }
    };
    xhttp.open("POST", "/member/signout", true);
    xhttp.send();

}
//window.onbeforeunload = function (e) {
//    e.preventDefault();
//    $('#js--preloader-bof').fadeIn('slow');

//}
//window.onunload = function (e) {
//    e.preventDefault();
//    $('#js--preloader-bof').fadeIn('slow');
//}
//window.onload = function (e) {
//    e.preventDefault();
//    $('#js--preloader-bof').fadeOut('slow');

//}



